/* New login page styles */
body {
    margin: 0;
    padding: 0;
    font-family: 'Courier New', Courier, monospace;
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
}

.container {
    text-align: center;
    width: 90%;
    max-width: 400px;
    padding: 20px;
    box-sizing: border-box;
}

.form {
    display: flex;
    flex-direction: column;
}

.input {
    margin-bottom: 10px;
    padding: 6px;
    border: 1px solid #00ff00;
    /* Green border */
    border-radius: 0;
    /* Square corners */
    background-color: black;
    /* Black background */
    color: #ffffff;
    /* White text */
    caret-color: #00ff00;
    /* Green text cursor */
    font-family: 'Courier New', Courier, monospace;
    /* Monospace font */
    font-size: 1em;
    /* Standard font size */
}

.button {
    padding: 10px;
    border-radius: 0;
    border: 1px solid #00ff00;
    background-color: black;
    color: #00ff00;
    cursor: pointer;
    font-family: 'Courier New', Courier, monospace;
    font-size: 1em;
    transition: background-color 0.2s ease, transform 0.2s ease;
}

.button:hover {
    background-color: #00ff00;
    color: black;
    transform: scale(1.05);
}

.error {
    color: red;
    margin-top: 10px;
}

.success {
    color: green;
    margin-top: 10px;
}

.logo {
    width: 100px;
    height: auto;
    margin-bottom: 20px;
}

.info-text {
    margin-top: 20px;
    font-size: 0.8em;
    color: white;
    text-align: center;
    font-family: 'Courier New', Courier, monospace;
}

.info-text a {
    color: #61dafb;
    text-decoration: none;
}

.info-text a:hover {
    text-decoration: underline;
}

/* Responsive design adjustments */
@media (max-width: 480px) {
    .container {
        width: 100%;
        padding: 10px;
    }

    .input,
    .button {
        font-size: 1em;
        padding: 12px;
    }

    .title {
        color: white;
        font-size: 2em;
        margin-bottom: 20px;
    }

    .info-text {
        font-size: 0.7em;
    }
}

.title .highlight {
    color: #8a2be2;
    font-weight: bold;
}

/* Styling for the tip container */
.tip-container {
    position: absolute;
    bottom: 20px;
    width: 300px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Styling for the tip message */
.tip-message {
    background-color: black;
    color: #00ff00;
    padding: 5px 10px;
    border: 2px solid #00ff00;
    border-radius: 0;
    font-family: 'Courier New', Courier, monospace;
    text-align: center;
    font-size: 1em;
    text-transform: uppercase;
    position: relative;
    z-index: 1;
}

/* Styling for the arrow */
.tip-arrow {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #00ff00;
    margin-top: -1px;
    position: relative;
    top: -1px;
    z-index: 0;
}