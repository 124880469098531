.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 10px;
    /* Unified padding */
    background-color: black;
    box-sizing: border-box;
    font-family: 'Courier New', Courier, monospace;
    padding-top: 30px;
    /* Apply Courier font to the entire header */
}

.back-button {
    color: #00ff00;
    cursor: pointer;
    background: black;
    /* Box background color */
    border: 1px solid #00ff00;
    /* Green border */
    font-size: 1em;
    font-family: 'Courier New', Courier, monospace;
    /* Ensure Courier font for the button */
    padding: 10px 20px;
    /* Padding inside the box */
    border-radius: 0;
    /* Square corners */
    transition: background-color 0.2s ease, color 0.2s ease;
}

.back-button:hover {
    background-color: #00ff00;
    /* Invert background on hover */
    color: black;
    /* Invert text color on hover */
    transform: scale(1.05);
    /* Slightly enlarge the button on hover */
}

.header-title {
    color: #00ff00;
    font-size: 1.2em;
    text-align: center;
    flex-grow: 1;
    font-family: 'Courier New', Courier, monospace;
    /* Apply Courier font to the title */
}

.header-logo {
    width: 60px;
    /* Ensure a consistent logo size */
    height: auto;
    cursor: pointer;
}