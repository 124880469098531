/* Container for the chat screen */
.personal-message-page {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  background-color: black;
  color: #00ff00;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-bottom: 3px;
}

.personal-message-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
}

.messages-container {
  flex: 1;
  width: 100%;
  padding: 10px;
  background-color: black;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  box-sizing: border-box;
}

.message {
  display: flex;
  align-items: center;
  margin-bottom: -10px;
}

.message.sent {
  justify-content: flex-end;
  color: white;
  text-align: left;
}

.message.received {
  justify-content: flex-start;
  color: #00ff00;
  text-align: left;
}

.message-content {
  max-width: 80%;
  padding: 5px 10px;
  border-radius: 10px;
  display: inline-block;
  position: relative;
  word-wrap: break-word;
  /* Ensure long words break and wrap within the container */
  white-space: pre-wrap;
  /* Preserve line breaks but allow wrapping */
  overflow-wrap: break-word;
  /* Break long words and wrap */
}

.message-content small {
  display: block;
  color: #ccc;
  font-size: 0.75em;
  margin-top: -5px;
  text-align: right;
}

.message.sent .message-content small {
  color: #7d68f5;
  font-style: italic;
  font-size: 0.5em;
  margin-top: -15px;
}

.message.received .message-content small {
  color: #7d68f5;
  font-size: 0.5em;
  margin-top: -15px;
  text-align: left;
}

.send-message-form {
  display: flex;
  padding: 18px;
  background-color: black;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.message-input {
  flex: 1;
  padding: 6px;
  border: 1px solid #00ff00;
  border-radius: 0;
  background-color: black;
  color: #fff;
  caret-color: #00ff00;
  font-family: 'Courier New', Courier, monospace;
  font-size: 1em;
}

.send-button {
  padding: 10px 20px;
  border: 1px solid #00ff00;
  border-radius: 0;
  background-color: black;
  color: #00ff00;
  cursor: pointer;
  font-family: 'Courier New', Courier, monospace;
  font-size: 1em;
  transition: background-color 0.2s ease, transform 0.2s ease;
}

.message-content .indicator {
  font-size: 1.2em;
  /* Adjust the font size */
  margin-left: 5px;
  /* Space between the indicator and the message text */
  position: absolute;
  /* Position it absolutely within the message content */
  right: 0;
  /* Align it to the right */
  top: 30px;
  /* Align it to the top of the first line */
  transform: translateY(-50%);
  /* Vertically center it with the first line */
  color: #00ff00;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: black;
  color: #00ff00;
  padding: 20px;
  border: 1px solid #00ff00;
  border-radius: 5px;
  z-index: 1000;
  text-align: center;
}

.popup p {
  margin-bottom: 15px;
}

.popup button {
  padding: 10px 20px;
  border: 1px solid #00ff00;
  border-radius: 0;
  background-color: black;
  color: #00ff00;
  cursor: pointer;
  font-family: 'Courier New', Courier, monospace;
  font-size: 1em;
  transition: background-color 0.2s ease, transform 0.2s ease;
}

.popup button:hover {
  background-color: #00ff00;
  color: black;
}