/* Friends container */
.friends-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    padding: 0;
   
    background-color: black;
    margin: 0;
    position: relative;
}

/* Friends list */
.friends-list {
    flex: 1;
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    overflow-y: auto;
    background-color: black;
}

/* Friend item */
.friend-item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.friend-item:hover {
    background-color: #111;
}

/* Friend profile picture */
.friend-profile-pic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

/* Friend username */
.friend-username {
    font-size: 1.2em;
    color: #00ff00;
    font-family: 'Courier New', Courier, monospace;
    /* Apply Courier New font */
}

/* Responsive Design Adjustments */
@media (max-width: 768px) {
    .friends-container {
        padding: 10px;
    }

    .friend-item {
        padding: 1em;
    }

    .friend-profile-pic {
        width: 30px;
        height: 30px;
    }

    .friend-username {
        font-size: 1em;
    }
}