/* Messages container */
.messages-page {
    width: 100vw;
    height: 100vh;
    margin: 0;
    background-color: black;
    color: #00ff00;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-family: 'Courier New', Courier, monospace;
    padding-bottom: 10px;
}

.messages-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow-y: auto;
    padding-bottom: 50px;
    font-family: 'Courier New', Courier, monospace;
}

/* Chat list */
.chat-list {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: black;
    font-family: 'Courier New', Courier, monospace;
    padding-bottom: 50px;
}

/* Chat item */
.chat-item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    cursor: pointer;
    transition: background-color 0.2s ease;
    font-family: 'Courier New', Courier, monospace;
}

/* Tint the unseen chats */
.unseen-chat {
    background-color: rgba(0, 255, 0, 0.1);
    /* Slight green tint */
}

.chat-item:hover {
    background-color: #111;
}

/* Profile picture */
.chat-profile-pic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

/* Username styling */
.chat-username {
    font-size: 1.2em;
    color: #00ff00;
    font-family: 'Courier New', Courier, monospace;
    margin-top: -10px;
}

/* Create button */
.create-button {
    padding: 10px 10px;
    border-radius: 0;
    border: 2px solid #00ff00;
    background-color: black;
    color: #00ff00;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.2s ease, transform 0.2s ease;
    text-transform: uppercase;
    width: 80%;
    max-width: 400px;
    position: fixed;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    font-family: 'Courier New', Courier, monospace;
}

.create-button:hover {
    background-color: #00ff00;
    color: black;
    transform: scale(1.00);
}

/* Responsive Design Adjustments */
@media (max-width: 768px) {
    .chat-item {
        padding: 10px;
        font-family: 'Courier New', Courier, monospace;
    }

    .chat-profile-pic {
        width: 30px;
        height: 30px;
    }

    .chat-username {
        font-size: 1em;
        margin-top: -5px;
    }

    .create-button {
        font-size: 1em;
        font-family: 'Courier New', Courier, monospace;
    }
}

.chat-timestamp {
    font-size: 0.8em;
    color: #ccc;
    margin-left: 10px;
}